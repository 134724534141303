import axios from 'axios';
import {currentState} from "../store/store";
import {logoutAction} from "../store/actions/AuthActions";

const errorResponseHandler = (error) => {
    console.log(error)

    // check for errorHandle config
    if (error.config.hasOwnProperty('errorHandle') || error.config.errorHandle === false) {
        return Promise.reject(error);
    }
    switch (error.response.status) {
        case 401:
            logoutAction();
            break;
        default:
            break;
    }
    throw error;
}
let instance = null;
function configureAxiosInstance() {
    let token = "null";
    if (currentState) {
        token = currentState.auth.token;
    }
    instance = axios.create({
        baseURL: (localStorage.getItem('apiUrl') ? localStorage.getItem('apiUrl') : '/'),
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    instance.interceptors.response.use(response => response, errorResponseHandler);
}

configureAxiosInstance();

export {instance as axios, configureAxiosInstance};
