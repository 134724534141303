export const AppInitialState = {
    loaded: false
}

export function AppReducer (state = AppInitialState, action) {
    switch (action.type) {
        case 'APP_LOADED':
            return { ...state, loaded: true};
        default:
            return state;
    }
}