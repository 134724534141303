import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default class Loader extends Component {
    constructor (props) {
        super(props)
        this.state = { open: this.props.open }
    }
    open() {
        let _this = this;
        _this.setState({ open: true })
    }
    close() {
        let _this = this;
        _this.setState({ open: false })
    }
    getState () {
        let _this = this;
        return _this.state.open
    }
    renderInline() {
        return <CircularProgress size={this.props.size} />
    }
    renderFullScreen() {
        return (
            <div className="loader-full-screen">
                <div className="cover"></div>
                <CircularProgress size={this.props.size} />
            </div>
        )
    }
    renderFullSection() {
        return (
            <div className="loader-full-section">
                <div className="cover"></div>
                <CircularProgress size={this.props.size} />
            </div>
        )
    }
    renderFixed() {
        return (
            <div className="loader-fixed" style={{left: `calc(50% - (${this.props.size}px / 2))`}}>
                <CircularProgress size={this.props.size} />
            </div>
        )
    }
    componentWillReceiveProps(next) {
        if (next.open) {
            this.open()
        } else {
            this.close()
        }
    }
    render () {
        if (this.state.open) {
            if (this.props.type === "inline") {
                return this.renderInline()
            } else if (this.props.type === "fullscreen") {
                return this.renderFullScreen()
            } else if (this.props.type === "fullsection") {
                return this.renderFullSection()
            } else if (this.props.type === "fixed") {
                return this.renderFixed()
            } else {
                return this.renderFullScreen()
            }
        }
        else {
            return <span id="LOADER"></span>
        }
    }
}
