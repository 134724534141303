import {AppInitialState, AppReducer} from "./AppReducer";
import {AuthInitialState, AuthReducer} from "./AuthReducer";
import {UsersManagementReducer, UsersManagementInitialState} from "./UsersManagementReducer";
import {LayoutInitialState, LayoutReducer} from "./LayoutReducer";

export const initialState = {
    app: AppInitialState,
    auth: AuthInitialState,
    usersManagement: UsersManagementInitialState,
    layout: LayoutInitialState
}

export const RootReducer = ( {app, auth, usersManagement, layout }, action) => ({
    app: AppReducer(app, action),
    auth: AuthReducer(auth, action),
    usersManagement: UsersManagementReducer(usersManagement, action),
    layout: LayoutReducer(layout, action)
});
