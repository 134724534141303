import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

import { GiAmericanFootballBall, GiRadarSweep } from 'react-icons/gi';
import { MdSpaceDashboard } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { BiLogOut } from 'react-icons/bi';

import { logoutAction } from "../../store/actions/AuthActions";

const drawerWidth = 240;


const DrawerContent = ({ theme, handleDrawerToggle, navigate }) => (
    <>
        <div style={{ textAlign: 'center' }}>
            <img style={{ height: 64, width: 'auto' }} className='loginForm-logo' alt="logo" src="/images/logo_transparent_big.png" />
        </div>
        <Divider />
        <List>
            <ListItem button key={'dashboard'} onClick={() => {
                if (window.innerWidth < 1200) handleDrawerToggle()
                navigate("/dashboard")
            }}>
                <ListItemIcon>
                    <MdSpaceDashboard />
                </ListItemIcon>
                <ListItemText primary={'Dashboard'} />
            </ListItem>

            <ListItem button key={'radar'} onClick={() => {
                if (window.innerWidth < 1200) handleDrawerToggle()
                navigate("/radar")
            }}>
                <ListItemIcon>
                    <GiRadarSweep />
                </ListItemIcon>
                <ListItemText primary={'Bet Radar'} />
            </ListItem>

            <ListItem button key={'settings'} onClick={() => {
                if (window.innerWidth < 1200) handleDrawerToggle()
                navigate("/settings")
            }}>
                <ListItemIcon>
                    <FiSettings />
                </ListItemIcon>
                <ListItemText primary={'Settings'} />
            </ListItem>

            <ListItem button key={'logout'} onClick={() => {
                logoutAction();
                navigate("/");
            }}>
                <ListItemIcon>
                    <BiLogOut className='icon drawer-icon' />
                </ListItemIcon>
                <ListItemText primary={'Logout'} />
            </ListItem>
        </List>
    </>
)
export const Navbar = ({ mobileOpen, handleDrawerToggle, ...remainingProps }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { window } = remainingProps;
    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    background: 'rgb(8, 8, 99)',
                    width: { lg: mobileOpen ? `calc(100% - ${drawerWidth}px)` : '100%' },
                    ml: { lg: mobileOpen ? `${drawerWidth}px` : 0 },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className="d-flex">
                        <div style={{ display: 'flex', fontWeight: 'bold', fontSize: '1.5rem' }}>
                            <div className='text-light'>BET</div>
                            <div style={{ color: 'rgb(93, 240, 105)' }}>SMART</div>
                        </div>
                    </div>
                    <GiAmericanFootballBall className='icon mx-3' />
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { lg: mobileOpen ? drawerWidth : 0 }, flexShrink: { lg: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <DrawerContent
                        theme={theme} handleDrawerToggle={handleDrawerToggle} navigate={navigate}
                    />
                </Drawer>
                <Drawer
                    variant="persistent"
                    sx={{
                        display: { xs: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                >
                    <DrawerContent
                        theme={theme}
                        handleDrawerToggle={handleDrawerToggle}
                        navigate={navigate}
                    />
                </Drawer>
            </Box>
        </>
    )
}