import { currentState, dispatchMethod as dispatch } from "../store";

import {configureAxiosInstance} from "../../helpers/axios-instance";

function loadAppStorage() {
    let token = localStorage.getItem('token');
    if (token) {
        dispatch({
            type: "LOGIN",
            token: token
        });
    }
    configureAxiosInstance();
    dispatch({
        type: "APP_LOADED",
    });
}

export {loadAppStorage};