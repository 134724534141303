import React, { useEffect } from "react";
import { useTrackedState } from "./store/store.js";
import Login from "./views/login/Login";
import './App.scss';
import AppContent from "./AppContent";
import { loadAppStorage } from "./store/actions/AppActions";

function App() {
    const [state] = useTrackedState();

    useEffect(() => {
        loadAppStorage();
    }, []);

    return (
        <div>
            {state.app.loaded ?
                <>
                    {state.auth.token ?
                        <>
                            <AppContent />
                        </>
                        :
                        <Login />
                    } </> :
                <div>Loading</div>
            }
        </div>
    );
}

export default App;
