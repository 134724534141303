export const AuthInitialState = {
    token: localStorage.getItem('token'),
}

export function AuthReducer (state = AuthInitialState, action) {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, token: action.token, role: action.role};
        case 'LOGOUT':
            return { ...state, token: null};
        case 'LOAD_TOKEN':
            return { ...state, token: action.token}
        default:
            return state;
    }
}
