import {useReducer} from "react";
import { initialState, RootReducer } from "./reducers/RootReducer";
import { createContainer } from 'react-tracked';

const container = createContainer(() => useReducer(RootReducer, initialState));
const { Provider, useTracked } = container;
let dispatchMethod = () => {};
let currentState = null;

function DispatchExtract() {
    const [state, dispatch] = useTracked();
    dispatchMethod = dispatch;
    currentState = state;
    return (
        <></>
    )
}

const StoreProvider = ( { children }) => {
    return (
        <Provider>
            <DispatchExtract/>
            {children}
        </Provider>
    )
}

export {
    useTracked as useTrackedState,
    StoreProvider,
    currentState,
    dispatchMethod
}