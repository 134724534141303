import React from 'react'

import Dashboard from './views/dashboard'
import Radar from './views/radar'
import Settings from './views/settings'


const routes = [
    { path: '/', exact: true, name: 'Home', component: () => <div></div> },
    { path: '/dashboard', exact: true, name: 'Dashboard', component: () => <Dashboard /> },
    { path: '/radar', exact: true, name: 'Radar', component: () => <Radar /> },
    { path: '/settings', exact: true, name: 'Settings', component: () => <Settings /> },
]

export default routes
