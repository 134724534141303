import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// routes config
import routes from '../_routes'

const Content = () => {
    return (
            <Suspense fallback={''}>
                <Routes>
                    {routes.map((route, idx) => {
                        return (
                            route.component && (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    element={<route.component />}
                                    render={(props) => (
                                        <>
                                            <route.component {...props} />
                                        </>
                                    )}
                                />
                            )
                        )
                    })}
                    <Route path="/" element={<Navigate to ="/dashboard" />}/>
                </Routes>
            </Suspense>
    )
}

export default React.memo(Content)