import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import { mapper, bettingSitesList, sportsList } from "./helpers"
import { getRadars, deleteRadar, createRadar, editRadar } from "../../helpers/APIs/radarAPIs"
import Table from "../../components/table"
import Paper from '@mui/material/Paper';
import { cloneDeep } from "lodash"
import TextField from '@mui/material/TextField';
import * as moment from "moment"
import Swal from 'sweetalert2';
import Loader from "../../components/loader"
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CheckboxWithIcon from '../../components/CheckboxWithIcon';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default class Radar extends Component {
    state = {
        sport: 'tennis',
        matchString: '',
        expirationDate: "7",
        // expirationDate: moment.unix(moment().add(7, 'days').unix()).format("YYYY-MM-DDTHH:mm"),
        bettingSites: bettingSitesList.map(el => el.value),
        editRowToggled: { on: false, rowIndex: -1 },
        tempRowData: null,
        rows: [],
        screenLoader: false,
        formLoader: false,
        tableLoader: false,
        snackbarOpen: false,
        snackBackMessage: ''
    }

    componentDidMount() {
        this.getData("screenLoader")
    }

    setLoading = (loader, value) => this.setState({ [loader]: value })

    getData = async (loaderKey) => {
        this.setLoading(loaderKey, true)
        let res = await getRadars()
        this.setLoading(loaderKey, false)
        if (res.successed) {
            let _data = res.body;
            let rows = []
            _data.forEach((el) => {
                rows.push({
                    ...el,
                    expirationTime: moment.unix(el.expirationTime).format("YYYY-MM-DD HH:mm"),
                })
            });
            this.setState({ rows })
        } else Swal.fire('Error!', "Couldn't get previous radars' data ", "error")
    }


    handleChangeTableCell = (key, value, index) => {
        let rows = this.state.rows;
        rows[index][key] = value
        this.setState({ rows })
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value })
    }

    validateDateChange = (value, index = -1) => {
        if (moment().valueOf() > moment(value, 'YYYY-MM-DDTHH:mm').valueOf()) {
            const { rows, tempRowData } = this.state;
            Swal.fire(
                '',
                'Please make sure to choose a date bigger than the current one',
                'warning'
            ).then(() => {
                if (index >= 0) {
                    let _rows = cloneDeep(rows);
                    let _tempRowData = cloneDeep(tempRowData)
                    _rows[index].expirationTime = moment(_tempRowData.expirationTime).format("YYYY-MM-DDTHH:mm")
                    this.setState({ rows: _rows })
                } else this.setState({ expirationDate: moment.unix(moment().add(7, 'days').unix()).format("YYYY-MM-DDTHH:mm") })
            })
        } else return;
    }

    handleDateChangeTableCell = (value, index) => {
        if (moment().valueOf() > moment(value, 'YYYY-MM-DDTHH:mm').valueOf()) {
            Swal.fire(
                '',
                'Please make sure to choose a date bigger than the current one',
                'warning'
            )
        } else {
            let rows = this.state.rows;
            rows[index].expirationTime = value
            this.setState({ rows })
        }
    }

    handleBettingSites = (key, value) => {
        this.setState(prev => ({
            bettingSites: value ? [...prev.bettingSites, key] : prev.bettingSites.filter(el => el !== key),
        }))
    }

    toggleRowToggleEdit = (el, index) => {
        let rows = this.state.rows;
        if (this.state.tempRowData) {
            rows[this.state.editRowToggled.rowIndex] = this.state.tempRowData
        }
        this.setState({
            editRowToggled: { on: true, rowIndex: index },
            tempRowData: cloneDeep(el),
            rows: rows
        })
    }

    handleCancel = (index) => {
        let rows = this.state.rows;
        rows[index] = this.state.tempRowData
        this.setState({
            editRowToggled: { on: false, rowIndex: -1 },
            tempRowData: null,
            rows: rows
        })
    }

    handleEditRow = async (el) => {
        this.setLoading("tableLoader", true)
        let payload = {
            sport: el.sport,
            matchString: el.matchString,
            expirationTime: moment(el.expirationTime, 'YYYY-MM-DDTHH:mm').unix(),
            bettingSite: el.bettingSite
        }
        let res = await editRadar(el.id, payload)
        this.setLoading("tableLoader", false)
        if (res.successed) {
            this.setState({
                snackbarOpen: true,
                snackBackMessage: "Radar has been edited.",
                editRowToggled: { on: false, rowIndex: -1 },
                tempRowData: null
            })
            this.getData("tableLoader")
        } else Swal.fire('Error!', "Couldn't edit radar", "error")
    }

    handleDelete = async (el) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.setLoading("tableLoader", true)
                let res = await deleteRadar(el.id)
                this.setLoading("tableLoader", false)
                if (res.successed) {
                    this.setState({
                        snackbarOpen: true,
                        snackBackMessage: "Radar has been deleted."
                    })
                    this.getData("tableLoader")
                } else Swal.fire('Error!', "Couldn't delete radar", "error")
            }
        })
    }

    handleCreate = async (e) => {
        e.preventDefault();
        const {
            sport,
            matchString,
            expirationDate,
            bettingSites
        } = this.state;
        let res
        for (let el of bettingSites) {
            this.setLoading("formLoader", true)
            res = await createRadar({
                sport: sport,
                matchString,
                expirationTime: expirationDate ? moment().add(Number(expirationDate), 'days').unix() : moment().add(7, 'days').unix(),
                bettingSite: el
            })
            this.setLoading("formLoader", false)
            if (!res.successed) {
                Swal.fire('Error!', "Couldn't create radar", "error")
            }
        }
        this.setLoading("formLoader", false)
        this.setState({
            snackbarOpen: true,
            snackBackMessage: "Radar(s) has been created.",
            sport: "tennis",
            matchString: "",
            expirationDate: "7",
            bettingSites: bettingSitesList.map(el => el.value)
        })
        this.getData("formLoader")
    }

    handleCloseSnackBar = (_, reason) => {
        if (reason === 'clickaway') return;
        this.setState({ snackbarOpen: false });
    };

    render() {
        const {
            sport,
            matchString,
            expirationDate,
            bettingSites,
            editRowToggled,
            rows,
            screenLoader,
            formLoader,
            tableLoader,
            snackbarOpen,
            snackBackMessage
        } = this.state;
        return (
            <div>
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbarOpen} autoHideDuration={6000} onClose={this.handleCloseSnackBar}>
                    <Alert onClose={this.handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                        {snackBackMessage}
                    </Alert>
                </Snackbar>
                <Loader type="fullscreen" size={100} open={screenLoader} />
                <Card variant="outlined" className="mt-4 m-auto" style={{ width: '90%' }}>
                    <Loader type="fullsection" size={100} open={formLoader} />
                    <Paper elevation={3}>
                        <form onSubmit={this.handleCreate}>
                            <CardHeader title="Create new radar" />
                            <CardContent>
                                <Grid container spacing={2}
                                    sx={{
                                        justifyContent: {
                                            xs: 'center',
                                            md: "space-between",
                                            lg: 'space-between'
                                        },
                                    }}
                                    alignItems="center"
                                >
                                    <Grid item xs={12} sm={6} md={4} lg={2}>
                                        <TextField
                                            label="Search Text"
                                            variant="outlined"
                                            name="matchString"
                                            style={{ width: '100%' }}
                                            value={matchString}
                                            onChange={(e) => this.handleChange("matchString", e.target.value)}
                                            placeholder='Enter search text here'
                                            required
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={2}>
                                        <TextField
                                            select
                                            label="Sport"
                                            value={sport}
                                            onChange={(e) => this.handleChange("sport", e.target.value)}
                                            style={{ width: '100%' }}
                                            size="small"
                                            required
                                        >
                                            {sportsList.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={2}>
                                        <TextField
                                            select
                                            label="Expiration Date"
                                            value={expirationDate}
                                            onChange={(e) => this.handleChange("expirationDate", e.target.value)}
                                            style={{ width: '100%' }}
                                            size="small"
                                            required
                                        >
                                            {[1, 2, 3, 4, 5, 6, 7].map((el) => (
                                                <MenuItem key={el} value={el}>
                                                    {`${el} day(s)`}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={5} lg={3} display="flex" justifyContent="space-around">
                                        <FormControl required sx={{ m: 0 }} component="fieldset" variant="standard">
                                            <FormGroup className="d-flex flex-row">
                                                {
                                                    bettingSitesList.map(el => {
                                                        let checked = bettingSites.includes(el.value)
                                                        return (
                                                            <FormControlLabel
                                                                style={{ marginLeft: 0 }}
                                                                key={el.label}
                                                                control={
                                                                    <CheckboxWithIcon
                                                                        checked={checked}
                                                                        handleOnChange={(e) => {
                                                                            this.handleBettingSites(el.value, !checked)
                                                                        }}
                                                                        src={el.src}
                                                                    />
                                                                }

                                                                label={""}
                                                            />
                                                        )
                                                    })
                                                }
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2} display="flex" justifyContent="center">
                                        <Button
                                            type="submit"
                                            disabled={!bettingSites.length || !matchString || !sport}
                                            className="btn btn-primary"
                                            style={{ background: 'rgb(8, 8, 99)', color: 'white' }}
                                        >Create Radar</Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </form>
                    </Paper>
                </Card>

                <Card variant="outlined" className="mt-4 m-auto" style={{ width: '90%' }}>
                    <Loader type="fullsection" size={100} open={tableLoader} />
                    <CardHeader title="Radars list" />
                    <CardContent>
                        {/* <Paper className="container w-100" elevation={1}> */}
                        <Table
                            data={rows}
                            mapper={mapper(this.handleCancel, this.handleEditRow, this.toggleRowToggleEdit, this.handleDelete, editRowToggled, this.handleChangeTableCell)}
                            pagination={true}
                            useSlice={true}
                            useParentPaginationProps={false}
                        />
                        {/* </Paper> */}
                    </CardContent>
                </Card>

            </div>
        )
    }
}