import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Navbar } from "./layout/navBar/navbar"
import Layout from "./layout/Content";

const drawerWidth = 240;


const AppContent = (props) => {
    const [mobileOpen, setMobileOpen] = React.useState(window.innerWidth >= 1200);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Navbar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="main"
                sx={{ width: { xs: "100%", lg: mobileOpen ? `calc(100% - ${drawerWidth}px)` : '100%' } }}
            >
                <Toolbar />
                <Layout />
            </Box>
        </Box>
    );
}

export default AppContent;