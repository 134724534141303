export const UsersManagementInitialState = {
    users: [],
}

export function UsersManagementReducer (state = UsersManagementInitialState, action) {
    switch (action.type) {
        case 'LIST':
            return { ...state, users: []};
        default:
            return state;
    }
}
