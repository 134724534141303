import React from 'react';
// import Checkbox from '@mui/material/Checkbox';
import { FaCheckCircle } from 'react-icons/fa';

const CheckboxWithIcon = ({ handleOnChange, checked, src }) => {
    return <div style={{ position: 'relative' }}>
        {checked && <FaCheckCircle style={{ position: 'absolute', right: 0, height: 16, width: 'auto', color: '#5df069', bottom: 25, zIndex: 2 }} />}
        <img onClick={handleOnChange} src={src} alt="" style={{ width: "auto", height: '40px', filter: `brightness(${checked ? '100%' : '50%'})` }} />

    </div>;
}

export default CheckboxWithIcon;
