import axios from "axios";
import { currentState, dispatchMethod as dispatch } from "../store";
import { login } from "../../helpers/APIs/authAPIs"
import {configureAxiosInstance} from "../../helpers/axios-instance";

async function loginAction(email, password) {
    let res = await login({ login: email, password });
    if (res.successed) {
        localStorage.setItem("token", res.body.token);
        dispatch({
            type: "LOGIN",
            token: res.body.token,
            role: res.body.role
        });
        configureAxiosInstance();
        return true;
    }
    return false;
}

function logoutAction() {
    localStorage.clear();
    dispatch({
        type: "LOGOUT"
    })
}

export {
    loginAction,
    logoutAction
}