export const LayoutInitialState = {
    sidebarUnfoldable: false,
    sidebarShow: true
}

export function LayoutReducer (state = LayoutInitialState, {type, ...rest}) {
    switch (type) {
        case 'SET_LAYOUT_VALUE':
            return { ...state, ...rest};
        default:
            return state;
    }
}
