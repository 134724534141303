import {axios} from "../axios-instance"

export const createRadar = async (data) => {
    try {
        let axiosConfig = {
            method: 'POST',
            url: `/api/radars/create`,
            data
        };

        const res = await axios(axiosConfig)
        return { successed: true, status: 'success', body: res.data };
    }
    catch (e) {
        return { successed: false, status: "error", error: e };
    }
}

export const editRadar = async (id, data) => {
    try {
        let axiosConfig = {
            method: 'PUT',
            url: `/api/radars/edit/${id}`,
            data
        };

        const res = await axios(axiosConfig)
        return { successed: true, status: 'success', body: res.data };
    }
    catch (e) {
        return { successed: false, status: "error", error: e };
    }
}

export const deleteRadar = async (id) => {
    try {
        let axiosConfig = {
            method: 'DELETE',
            url: `/api/radars/delete/${id}`,
        };
        const res = await axios(axiosConfig)
        return { successed: true, status: 'success', body: res.data };
    }
    catch (e) {
        return { successed: false, status: "error", error: e };
    }
}

export const getRadars = async () => {
    try {
        let axiosConfig = {
            method: 'GET',
            url: `/api/radars/list`,
        };

        const res = await axios(axiosConfig)
        return { successed: true, status: 'success', body: res.data };
    }
    catch (e) {
        return { successed: false, status: "error", error: e };
    }
}