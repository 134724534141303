import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useTrackedState } from "../../store/store";
import { loginAction } from "../../store/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { login } from "../../helpers/APIs/authAPIs"
import './login.scss';

function Login() {
    // state
    const { auth } = useTrackedState()[0];
    const navigate = useNavigate();

    // local state
    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        navigate("/");
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true)
        let tryLogin = await loginAction(account, password);
        setLoading(false)
        if (!tryLogin) {
            Swal.fire({
                title: 'Login Failed',
                icon: 'error',
                text: 'Your email or password is incorrect',
                confirmButtonColor: 'red'
            })
        }
    };

    return (
        <div className='d-flex loginForm'>
            <div className="loginForm-container p-5">
                <Form onSubmit={handleSubmit}>
                    <img className='loginForm-logo' alt="logo" src="/images/logo_transparent_big.png" />
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control name="account" className="p-2" type="email" placeholder="Enter email" onChange={(e) => setAccount(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control name="password" className="p-2" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                    <Button type="submit" className='btn btn-main w-100 p-3 text-uppercase'
                        disabled={loading || !account || !password}>
                        {loading ? <div className="spinner-border text-light" role="status"></div> : 'Submit'}
                    </Button>
                    <div className='d-flex justify-content-between'>
                        <div className="loginForm-alert leftAlertBorder">
                            New to Bet Smart? <strong className="d-inline-block text-decoration-underline">Sign up!</strong> today.
                        </div>
                        <div className="loginForm-alert rightAlertBorder">
                            Cannot access your account? <strong className='text-decoration-underline'>Lost Account</strong>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Login
