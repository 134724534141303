import React from "react";
import { MdDeleteForever, MdEdit, MdSend, MdCancel } from 'react-icons/md';
import { FaCheckCircle } from 'react-icons/fa';
import TextField from '@mui/material/TextField';
import { capitalize } from "../../helpers/stringFunctions"
import MenuItem from '@mui/material/MenuItem';
import RadarIcon from "../../components/radar";


export const selectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        zIndex: 99999,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '40px',
        height: '40px',
        boxShadow: state.isFocused ? null : null,
    }),
    menu: styles => ({ ...styles, zIndex: 99999 }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '40px',
    })
}

const Icon = ({ src, checked }) => (
    !checked ? <img src={src} alt="" style={{ width: "auto", height: '40px' }} />
        : <div style={{ position: 'relative' }}>
            <FaCheckCircle style={{ position: 'absolute', right: 0, height: 16, width: 'auto', color: '#5df069', bottom: 25 }} />
            <img src={src} alt="" style={{ width: "auto", height: '40px' }} />
        </div>
);

export const mapper = (handleCancel, handleEditRow, toggleRowToggleEdit, handleDelete, editRowToggled, handleOnChange) => (
    [
        {
            label: "",
            render: () => <div style={{ position: 'relative', width: 40, height: 40 }}><RadarIcon /></div>
        },
        {
            label: 'Search text',
            render: (el, i) => editRowToggled.on && editRowToggled.rowIndex === i ?
                <TextField
                    label="Search Text"
                    size="small"
                    variant="outlined"
                    name="matchString"
                    style={{ width: '100%', minWidth: 150 }}
                    value={el.matchString}
                    onChange={(e) => handleOnChange("matchString", e.target.value, i)}
                    required
                />
                : el.matchString
        },
        {
            label: 'Sport',
            render: (el, i) => {
                return (editRowToggled.on && editRowToggled.rowIndex === i ?
                    <TextField
                        select
                        label="Sport"
                        value={el.sport}
                        onChange={(e) => handleOnChange("sport", e.target.value, i)}
                        style={{ width: '100%', minWidth: 150 }}
                        size="small"
                        required
                    >
                        {sportsList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    : el.sport ? capitalize(el.sport) : ""
                )
            }
        },
        {
            label: 'Betting site',
            render: (el) => el?.bettingSite
        },
        {
            label: 'ExpirationDate',
            render: (el) => el?.expirationTime
        },
        {
            label: "",
            render: (el, i) => {
                let editEnabled = el?.matchString && el?.sport;
                return (<div style={{ display: 'flex', justifyContent: 'center' }}>
                    {editRowToggled.on && editRowToggled.rowIndex === i ?
                        <>
                            <MdSend
                                style={{
                                    width: 24,
                                    height: 'auto',
                                    cursor: editEnabled ? 'pointer' : 'not-allowed',
                                    color: editEnabled ? 'rgb(8, 8, 99)' : 'grey',
                                    marginRight: 8
                                }}
                                onClick={() => handleEditRow(el)}
                            />
                            <MdCancel
                                onClick={() => handleCancel(i)}
                                style={{ color: "red", width: 24, height: 'auto', cursor: 'pointer' }}
                            />
                        </>
                        : <>
                            <MdEdit
                                onClick={() => toggleRowToggleEdit(el, i)}
                                style={{ width: 24, height: 'auto', marginRight: 8, cursor: 'pointer', color: 'rgb(8, 8, 99)' }}
                            />
                            <MdDeleteForever
                                onClick={() => handleDelete(el)}
                                style={{ color: "red", width: 24, height: 'auto', cursor: 'pointer' }}
                            />
                        </>}
                </div>)
            }
        }
    ]
);

export const bettingSitesList = [
    {
        label: "Bet365",
        value: "bet365",
        src: "/images/icons/bettingSites/Bet365.png",
        icon: <Icon checked={false} src="/images/icons/bettingSites/Bet365_dimmed.png" />,
        checkedIcon: <Icon checked={true} src="/images/icons/bettingSites/Bet365.png" />
    },
    {
        label: "Tipsport",
        value: "tipsport",
        src: "/images/icons/bettingSites/Tipsport.png",
        icon: <Icon checked={false} src="/images/icons/bettingSites/Tipsport_dimmed.png" />,
        checkedIcon: <Icon checked={true} src="/images/icons/bettingSites/Tipsport.png" />
    },
    {
        label: "Fortuna",
        value: "fortuna",
        src: "/images/icons/bettingSites/fortuna.jpg",
        icon: <Icon checked={false} src="/images/icons/bettingSites/fortuna.jpg" />,
        checkedIcon: <Icon checked={true} src="/images/icons/bettingSites/fortuna.jpg" />
    }
]

export const sportsList = [
    {
        label: "Tennis",
        value: "tennis"
    },
]