import React from 'react'
import "./index.scss"

const RadarIcon = () => {
    return (
        <div className="radar">
            <div className="pointer"></div>
            <div className="shadow"></div>
        </div>
    )
}

export default RadarIcon
