import {axios} from "../axios-instance"

export const login = async (data) => {
    try {
        let axiosConfig = {
            method: 'POST',
            url: `/api/auth/login`,
            data
        };

        const res = await axios(axiosConfig)
        return { successed: true, status: 'success', body: res.data };
    }
    catch (e) {
        return { successed: false, status: "error", error: e.response };
    }
}