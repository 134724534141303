import React, { Component } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import _ from "lodash"
import "./index.scss"

export default class DynamiTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5
        }
    }

    handleProps = (props) => {
        let data = props.data;
        props.dataTree.forEach(key => data = data[key])
        return {
            data,
            rowsPerPage: 5
        }
    }
    isArrayEqual = function (x, y) {
        return _(x).differenceWith(y, _.isEqual).isEmpty();
    };
    componentDidUpdate(prevProps, prevState) {
        if (!this.isArrayEqual(prevProps.data, this.props.data)) {
            this.setState({ data: this.props.data })
        }
    }

    handleChangePage = (_e, nextPage) => {
        this.setState({ page: nextPage })
    }

    handleChangeRowsPerPage = (e) => this.setState({ rowsPerPage: e.target.value })

    RenderTableHead = ({ mapper }) => {
        return (
            <TableHead>
                <TableRow>
                    {mapper.map((col, i) => <TableCell key={i} style={col.styleTH ? col.styleTH : {}}>{col.label}</TableCell>)}
                </TableRow>
            </TableHead>
        )
    }
    RenderTableBody = (tableBodyProps) => {
        const {
            useSlice,
            data,
            useParentPaginationProps,
            pageFromProps,
            rowsPerPageFromProps,
            tableRowOnClick,
            mapper
        } = tableBodyProps;
        const { page, rowsPerPage } = this.state
        return (
            <TableBody>
                {(useSlice ? data
                    .slice(...(!useParentPaginationProps ? [page * rowsPerPage, page * rowsPerPage + rowsPerPage] : [pageFromProps * rowsPerPageFromProps, pageFromProps * rowsPerPageFromProps + rowsPerPageFromProps]))
                    : data)
                    .map((record, i) => (
                        <TableRow
                            key={i}
                            onClick={() => { if (tableRowOnClick) { tableRowOnClick(record) } }}
                            style={tableRowOnClick ? { cursor: 'pointer' } : {}}
                        >
                            {
                                mapper.map(
                                    (col, j) => (
                                        <TableCell style={col.styleTD ? col.styleTD : {}} key={col.key || j}>
                                            {
                                                (col.render || (data => data[col.key]))(record, i)
                                            }
                                        </TableCell>
                                    ))
                            }
                        </TableRow>
                    ))}
            </TableBody>
        )
    }

    RenderTablePagination = (tablePaginationProps) => {
        const {
            count,
            data,
            useParentPaginationProps,
            rowsPerPageFromProps,
            pageFromProps,
            handleChangePageFromProps,
            handleChangeRowsPerPageFromProps
        } = tablePaginationProps;
        return (
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                component="div"
                count={count ? count : data.length}
                rowsPerPage={useParentPaginationProps ? rowsPerPageFromProps : this.state.rowsPerPage}
                page={useParentPaginationProps ? pageFromProps : this.state.page}
                onPageChange={useParentPaginationProps ? handleChangePageFromProps : this.handleChangePage}
                onRowsPerPageChange={useParentPaginationProps ? handleChangeRowsPerPageFromProps : this.handleChangeRowsPerPage}
            />
        )
    }
    render() {
        const { props, RenderTableHead, RenderTableBody, RenderTablePagination } = this
        return (
            <>
                <TableContainer className='myTable'>
                    <Table>
                        {RenderTableHead(props)}
                        {RenderTableBody(props)}
                    </Table>
                    {props.pagination && RenderTablePagination(props)}
                </TableContainer>
            </>
        )
    }
}